.mobNavSlide{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  z-index: 2;
  animation: moveRight .5s ease-in;
}
@keyframes moveRight {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}
.why-us .swiper-button-prev {
  left: 80px;
  display: none;
}
.why-us .swiper-button-next{
  display: none;
}